<template>
  <div id="tracker-nav" class="pt-3 pb-4 mx-auto shadow w-full fixed z-30 bg-white bg-opacity-30">
    <div class="w-full mx-auto px-4 sm:px-6 lg:px-8 flex-none md:flex">
      <div class="flex md:flex-none md:flex-grow">
        <template v-if="boardGridEdit">

          <div class="relative mt-2">
            <button @click="modeSwatchEdit = !modeSwatchEdit" class="relative w-15 mr-3 rounded-md pl-2 pr-2 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <svg v-if="boardData[0].mode === 'compact'" xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 inline-block" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="6" height="6" rx="1" />
                <rect x="14" y="4" width="6" height="6" rx="1" />
                <rect x="4" y="14" width="6" height="6" rx="1" />
                <rect x="14" y="14" width="6" height="6" rx="1" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 inline-block" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="6" height="16" rx="2" />
                <rect x="14" y="4" width="6" height="10" rx="2" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>

            <ul v-if="modeSwatchEdit" class="whitespace-normal absolute z-50 mt-1 w-60 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li
                      @click="modeBoardSwatch('default')"
                      class="cursor-pointer text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-50">
                <div class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 flex-shrink" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="4" y="4" width="6" height="16" rx="2" />
                    <rect x="14" y="4" width="6" height="10" rx="2" />
                  </svg>
                  <div class="font-medium ml-3 block">
                    <span class="leading-7">Default</span><br />
                    <span class="text-sm leading-normal font-normal text-gray-400">
                      Blocks display with height defined by their size.
                    </span>
                  </div>
                </div>
              </li>
              <li
                @click="modeBoardSwatch('compact')"
                class="cursor-pointer text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 border-t hover:bg-blue-50">
                <div class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 flex-shrink" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="4" y="4" width="6" height="6" rx="1" />
                    <rect x="14" y="4" width="6" height="6" rx="1" />
                    <rect x="4" y="14" width="6" height="6" rx="1" />
                    <rect x="14" y="14" width="6" height="6" rx="1" />
                  </svg>
                  <div class="font-medium ml-3 block">
                    <span class="leading-7">Compact</span><br />
                    <span class="text-sm leading-normal font-normal text-gray-400">
                      Blocks display with the same height.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="relative mt-2 border-r-2 mr-3 border-gray-100">
            <button @click="colorGridSwatchEdit('board')" type="button" class="cursor-pointer relative w-auto mr-3 align-center rounded-md pl-2 pr-2 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <div class="flex items-center">
                <span :class="'bg-' + boardData[0].color + '-400 ' + boardData[0].color + ' border-' + boardData[0].color + '-600'" class="border-2 flex-shrink-0 inline-block h-7 w-7 rounded-full"></span>
              </div>
            </button>
            <ul v-if="colorSwatchEdit === 'board'" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <li
                @click="colorBoardSwatch('board', '')"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="'bg-white'"
                  ></span>
                  <span class="font-normal ml-3 block truncate">
                    None
                  </span>
                </div>
                <span v-if="boardData[0].color === '' || boardData[0].color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
              <li
                v-for="color in bgGradients" v-bind:key="color.value"
                @click="colorBoardSwatch('gradient', color.value)"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="color.value"
                  ></span>
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal ml-3 block truncate">
                    {{color.text}}
                  </span>
                </div>
                <span v-if="boardData[0].color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
              <li
                v-for="color in colors" v-bind:key="color.value"
                @click="colorBoardSwatch('board', color.value)"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="'bg-' + color.value + '-400'"
                  ></span>
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal ml-3 block truncate">
                    {{color.text}}
                  </span>
                </div>
                <span v-if="boardData[0].color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
            </ul>
          </div>

          <div class="mt-2 hidden md:inline-flex flex-grow md:flex-grow-0 border-r-2 mr-3 border-gray-100">
            <button
              data-im-id="button-block-add"
              type="button"
              @click="blockAdd('Block','block')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
            </button>
            <button
              data-im-id="button-blockcounter-add"
              type="button"
              @click="blockAdd('BlockCounter', 'blockcounter')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd"></path></svg>
            </button>
            <button
              data-im-id="button-blockcountdown-add"
              type="button"
              @click="blockAdd('BlockCountdown', 'blockcountdown')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
            </button>
            <button
              data-im-id="button-blockprogress-add"
              type="button"
              @click="blockAdd('BlockProgress', 'blockprogress')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
            </button>
            <button
              data-im-id="button-blockcollector-add"
              type="button"
              @click="blockAdd('BlockCollector', 'blockcollector')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
              </svg>
            </button>

            <button
              data-im-id="button-blocklink-add"
              type="button"
              @click="blockAdd('BlockLink', 'blocklink')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0 1 11.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 0 1-1.085.67L12 18.089l-7.165 3.583A.75.75 0 0 1 3.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93Z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>

          <div class=" md:inline-flex flex-grow md:flex-grow-0 flex">
            <div class="flex-grow text-right">
              <button @click="boardGridEditing" type="button"
                class="mt-2 relative border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
                style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="font-bold ml-1">Cancel</span>
              </button>
            </div>
            <div>
              <button @click="saveAll" type="button"
                class="mt-2 ml-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
                style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
              >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                </svg>
                <span class="font-bold ml-1">Save</span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="second-col mt-2 flex md:mt-0">
        <div class="mt-2 inline-flex md:hidden flex-grow md:flex-grow-0 border-r-2 mr-3 border-gray-100">
          <button
                  data-im-id="button-block-add"
                  type="button"
                  @click="blockAdd('Block','block')"
                  :disabled="!allowBlockAdd"
                  class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
                  style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
            <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
          </button>
          <button
                  data-im-id="button-blockcounter-add"
                  type="button"
                  @click="blockAdd('BlockCounter', 'blockcounter')"
                  :disabled="!allowBlockAdd"
                  class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
                  style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
            <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd"></path></svg>
          </button>
          <button
                  data-im-id="button-blockcountdown-add"
                  type="button"
                  @click="blockAdd('BlockCountdown', 'blockcountdown')"
                  :disabled="!allowBlockAdd"
                  class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
                  style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
            <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
          </button>
          <button
                  data-im-id="button-blockprogress-add"
                  type="button"
                  @click="blockAdd('BlockProgress', 'blockprogress')"
                  :disabled="!allowBlockAdd"
                  class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
                  style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
            <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
          </button>
          <button
                  data-im-id="button-blockcollector-add"
                  type="button"
                  @click="blockAdd('BlockCollector', 'blockcollector')"
                  :disabled="!allowBlockAdd"
                  class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
                  style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
          >
            <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
            </svg>
          </button>
        </div>

        <div data-im-id="timer-session" class="text-left md:text-right mt-1 md:mt-0 hidden md:flex-grow md:border-l-2 md:ml-3 md:pl-1">
          <p class="text-xs text-gray-500 uppercase">Session</p>
          <span class="z-10 text-gray-300 md:pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div data-im-id="timer-total" class="hidden md:flex-shrink md:flex-none mt-1 md:mt-0 text-left md:text-right border-l-2 ml-3 pl-1">
          <p class="text-xs pl-2 text-gray-500 uppercase">Total</p>
          <span class="z-10 text-gray-300 pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div class="mt-2 text-right border-l-2 ml-3 pl-3 hidden md:block">
          <button data-im-id="button-freshstart" type="button" :disabled="isLoading || boardGridEdit"
            class="inline-flex items-center p-2 pb-3 border-2 border-b-3 border-blue-900 border-transparent rounded-md shadow-sm text-blue-900 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style="box-shadow: inset 0px -6px 0px #93C5FD, inset 0 -7px 0 0 #EFF6FF, inset 0px 1px 0px 1px #EFF6FF;"
          >
            <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <span class="font-bold ml-1">Fresh start</span>
          </button>
        </div>
      </div>
    </div>

  </div>
  <main id="tracker-main" class="pt-2">
    <div v-if="isLoading" class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2" style="margin-top: 82px;">
      <div class="inline-flex text-base leading-6 font-medium rounded-md text-white text-gray-600 cursor-not-allowed">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading fun assets...
      </div>
    </div>
    <div v-if="boardGridEdit" style="margin-top: 82px;">
      <div class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8" style="width: 80rem;">
        <draggable
          :list="boardData[0].rows"
          group="rows"
          itemKey="id"
          handle=".row-drag-handle"
        >
          <template #item="{ element, index }">
            <div class="rounded bg-opacity-80 my-3" :class="'bg-' + element.color + '-50'" :id="'row-' + index">
              <div class="flex items-center">
                <div class="flex-shrink row-drag-handle cursor-move">
                  <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="5" r="1" /><circle cx="9" cy="12" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="19" r="1" />
                  </svg>
                </div>
                <div class="flex-shrink font-medium p-1">
                  <input v-model="element.title"
                   :class="'text-' + element.color + '-500 placeholder-' + element.color + '-300'"
                   class="inline-block p-1 text-left font-medium tracking-wider bg-transparent" placeholder="Empty Row Title" />
                </div>
                <div class="flex-shrink font-medium text-gray-500">
                  <div class="relative">
                    <button @click="colorGridSwatchEdit(element.id)" type="button" class="relative w-9 rounded-md pl-2 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <div class="flex items-center">
                        <span :class="'bg-' + element.color + '-400 border-' + element.color + '-600'" class="border-2 flex-shrink-0 inline-block h-5 w-5 rounded-full"></span>
                      </div>
                    </button>
                    <ul v-if="colorSwatchEdit === element.id" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      <li
                        @click="colorGridSwatch(element, '')"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                        <div class="flex items-center">
                          <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                :class="'bg-white'"
                          ></span>
                          <span class="font-normal ml-3 block truncate">
                            None
                          </span>
                        </div>
                        <span v-if="element.color === '' || element.color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                          <!-- Heroicon name: solid/check -->
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </span>
                      </li>
                      <li
                        v-for="color in colors" v-bind:key="color.value"
                        @click="colorGridSwatch(element, color.value)"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                        <div class="flex items-center">
                          <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                          <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                :class="'bg-' + color.value + '-400'"
                          ></span>
                          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                          <span class="font-normal ml-3 block truncate">
                            {{color.text}}
                          </span>
                        </div>
                        <span v-if="element.color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                          <!-- Heroicon name: solid/check -->
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex-shrink flex align-middle mx-2 mr-4 text-xs border-l-2 border-gray-100 pl-4">
                  <button @click="element.freshStart = !element.freshStart" type="button"
                          class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          :class="{'bg-indigo-600': element.freshStart}"
                          role="switch" aria-checked="false">
                    <span class="sr-only">Use setting</span>
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span class="translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          :class="{'translate-x-4': element.freshStart}"
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            aria-hidden="true"
                            :class="{'opacity-100 ease-in duration-200': !element.freshStart}"
                      >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                          <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            :class="{'opacity-100 ease-in duration-200': element.freshStart}"
                            aria-hidden="true">
                        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                  </button>
                  <span class="inline-flex pl-2.5 pr-0 py-1.5 text-xs font-medium text-gray-700">Fresh start</span>
                </div>
                <div class="flex-grow font-medium text-gray-500 border-l-2 border-gray-100 pl-4">
                  <button
                    @click="boardAddColumn(index)"
                    :disabled="element.cols.length >= limits.columns"
                    type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Add Column
                  </button>
                </div>
                <button
                  class="flex-shrink cursor-pointer"
                  @click="boardRemoveRow(index)"
                >
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
              </div>

            <draggable
              :list="element.cols"
              :group="element.id"
              itemKey="id"
              handle=".column-drag-handle"
              class="grid gap-6 p-2"
              :class="'grid-cols-' + element.cols.length"
            >
              <template #item="{ element }">
                <div class="rounded bg-opacity-80" :class="'bg-' + element.color + '-100'">
                  <div class="flex items-center justify-between">
                    <div class="flex-shrink">
                      <div class="relative">
                        <button @click="colorGridSwatchEdit(element.id)" type="button" class="relative w-8 rounded-md pl-2 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <div class="flex items-center">
                            <span :class="'bg-' + element.color + '-400 border-' + element.color + '-600'" class="border-2 flex-shrink-0 inline-block h-4 w-4 rounded-full"></span>
                          </div>
                        </button>
                        <ul v-if="colorSwatchEdit === element.id" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          <li
                            @click="colorGridSwatch(element, '')"
                            class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center">
                            <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                  :class="'bg-white'"
                            ></span>
                              <span class="font-normal ml-3 block truncate">
                              None
                            </span>
                            </div>
                            <span v-if="element.color === '' || element.color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                            <!-- Heroicon name: solid/check -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                          </li>
                          <li
                            v-for="color in colors" v-bind:key="color.value"
                            @click="colorGridSwatch(element, color.value)"
                            class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center">
                              <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                              <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                    :class="'bg-' + color.value + '-400'"
                              ></span>
                              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                              <span class="font-normal ml-3 block truncate">
                              {{color.text}}
                            </span>
                            </div>
                            <span v-if="element.color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                            <!-- Heroicon name: solid/check -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="column-drag-handle cursor-move">
                      <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
                      </svg>
                    </div>
                    <button
                      class="flex-shrink cursor-pointer"
                      @click="boardRemoveColumn(index, element.id)"
                      :disabled="element.data.length > 0"
                    >
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                  </div>
                  <div class="flex">
                    <div class="flex-grow font-medium text-gray-500">
                      <input
                        v-model="element.title"
                        :class="'text-' + element.color + '-500 placeholder-' + element.color + '-300'"
                        class="mx-2 placeholder-gray-300 p-2 text-left font-medium text-gray-500 tracking-wider bg-transparent w-full" placeholder="Empty Column Title" />
                    </div>
                  </div>
                  <div class="p-2">
                    <draggable
                      :list="element.data"
                      group="blocks"
                      itemKey="id"
                      @start="boardDrag=true"
                      @end="boardDrag=false"
                      :animation="200"
                      handle=".block-drag-handle"
                      class="col-transition grid gap-y-4 grid-cols-1 h-full content-start min-h-16 column"
                      :class="{'custom-outline': boardDrag}"
                    >
                      <template #item="{element}">
                        <div>
                          <component
                            :is="blocksData[blockIndexById(element.block)].type || 'Block'"
                            v-bind="blocksData[blockIndexById(element.block)]"
                            :blockTick="blockTick"
                            :boardMode="boardData[0].mode"
                            :buildMode="true"
                            :projects="this.$store.state.projects.projects"
                            @block-reset="blockReset"
                            @block-added="blockAdded"
                            @block-delete="blockDelete"
                            @block-edit-cancel="blockEditCancel"
                            @block-edit-start="blockEditStart"
                            @block-notification-update="blockNotificationUpdate"
                            @block-update="blockUpdate"
                            @block-add-time="blockAddTime"
                            @block-remove-time="blockRemoveTime"
                          />
                        </div>
                      </template>
                    </draggable>

                  </div>
                </div>
              </template>
            </draggable>
            </div>
          </template>
          <template #footer>
            <button
              class="inline-block w-auto mx-2 h-10 bg-gray-600 text-white 00 m-2 p-2 rounded"
              @click="boardAddRow()"
              :disabled="boardData[0].rows.length >= limits.rows"
            >
              Add Row
            </button>
          </template>
        </draggable>
      </div>
    </div>

    <div class="anime-container bg-green-200 border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200 border-0 border-green-600 w-8 h-8 absolute rounded-lg opacity-0 hidden"></div>
  </main>

  <div v-if="blockEdited.id" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <component
      v-if="blockEdited.id"
      :is="blockEdited.type + 'Edit'"
      v-bind:key="blockEdited.id"
      v-bind:id="blockEdited.id"
      v-bind:block="blockEdited"
      v-bind:projects="projects"
      v-bind:blocks="blocks"
      @block-edit-stop="blockEditStop"
      @block-update="blockUpdate"
    ></component>
  </transition>

  <div v-if="saveProcess" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div @click="saveProcess = false" v-if="saveProcess === 'failed' || saveProcess === 'success'" class="absolute right-2 top-2 cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div class="">
          <div class="text-center sm:mt-5">
            <div v-if="saveProcess === 'failed' && saveProcessError">
              <div  class="text-sm text-red-500 font-medium mb-2">
                <p class="text-center">
                  <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                  {{saveProcessError}}
                </p>
                <div class="border-t border-gray-200 mt-3 text-gray-500 font-normal">
                  <p class="mt-3">
                    It could be because of:
                    <ul>
                      <li>1. You are not connected to the internet</li>
                      <li>2. An internal error on our side</li>
                    </ul>
                  </p>
                </div>
                <div class="flex mt-3 justify-center">
                  <button @click="saveAll" type="button" class="px-4 inline-flex mr-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:col-start-2 sm:text-sm">
                    Try again
                  </button>
                </div>
              </div>
              <div class="border-t border-gray-200 mt-3 text-gray-500 font-normal">
                <p class="mt-3 text-sm">
                  Still does not work? Make a backup and send it to support@getkairo.com.<br />
                  <a @click="saveBackup" class="underline cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    Backup your board</a>
                </p>
              </div>
            </div>
            <div v-if="saveProcess === 'running'">
              <div class="text-sm text-gray-500 font-medium mb-2">
                <p class="text-center">
                  <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                  We are saving what you have built...
                </p>
              </div>
            </div>
            <div v-if="saveProcess === 'success'">
              <div class="text-sm text-green-500 font-medium mb-2">
                <p class="text-center">
                  <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                  Your changes were successfully saved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import Block from '@/components/Block.vue';
  import BlockCounter from '@/components/BlockCounter.vue';
  import BlockCountdown from '@/components/BlockCountdown.vue';
  import BlockProgress from '@/components/BlockProgress.vue';
  import BlockCollector from '@/components/BlockCollector.vue';
  import BlockLink from '@/components/BlockLink.vue';
  import BlockEdit from '@/components/BlockEdit.vue';
  import BlockCounterEdit from '@/components/BlockCounterEdit.vue';
  import BlockCountdownEdit from '@/components/BlockCountdownEdit.vue';
  import BlockProgressEdit from '@/components/BlockProgressEdit.vue';
  import BlockCollectorEdit from '@/components/BlockCollectorEdit.vue';
  import BlockLinkEdit from '@/components/BlockLinkEdit.vue';
  import BlockIcon from '@/components/BlockIcon.vue';
  import {colors} from '../colors/colors.js';
  import {skins} from '../colors/skins.js';
  import {presets} from '../presets/free.js';
  import { v4 as uuidv4 } from 'uuid';
  import {diffString} from 'json-diff';
  import {
    deleteBlock,
    updateBlock,
    getBlocks,
    createBlock
  } from "../models/BlocksModel";
  import {
    getBoards,
    createBoard, updateBoard
  } from "../models/BoardsModel";
  import {mapGetters, mapActions} from "vuex";
  import { useToast } from "vue-toastification";
  import draggable from 'vuedraggable';
  import {nextTick} from "vue";
  import { useNativeNotifications } from "vue3-native-notification";
  import {getProjects} from "../models/ProjectsModel";
  import anime from "animejs";

  export default {
    name: 'Tracker',
    components: {
      draggable,
      Block,
      BlockCounter,
      BlockCountdown,
      BlockProgress,
      BlockCollector,
      BlockLink,
      BlockEdit,
      BlockCounterEdit,
      BlockCountdownEdit,
      BlockProgressEdit,
      BlockCollectorEdit,
      BlockLinkEdit,
      BlockIcon,
    },
    setup() {
      const toast = useToast();
      const nativeNotification = useNativeNotifications();
      return { toast, nativeNotification };
    },
    data() {
      return {
        boards: this.$store.state.tracker.boards,
        blocks: this.$store.state.tracker.blocks,
        projects: this.$store.state.projects.projects,
        favicons: {},
        timerTotal: '00:00:00',
        timerSession: '00:00:00',
        blockTick: new Date().getTime() + 1000,
        worker: null,
        limits: {
          blocks: 32,
          columns: 8,
          rows: 4
        },
        isLoading: false,
        saveProcess: false,
        saveProcessError: '',
        jobQueue: [],
        jobQueueInterval: '',
        jobQueueRunning: false,
        audio: null,
        toggleModal: false,
        colors,
        colorSwatchEdit: null,
        modeSwatchEdit: null,
        controller: new AbortController(),
        blockEdited: {},
        boardGridEdit: false,
        boardDrag: false,
        boardData: [],
        blocksData: [],
        formData: {},
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        bgGradients: [
          {
            text: 'Gradient 1',
            value: 'bg-grad-1',
          },
          {
            text: 'Gradient 2',
            value: 'bg-grad-2',
          },
          {
            text: 'Gradient 3',
            value: 'bg-grad-3',
          },
          {
            text: 'Gradient 4',
            value: 'bg-grad-4',
          },
          {
            text: 'Gradient 5',
            value: 'bg-grad-5',
          },
          {
            text: 'Gradient 6',
            value: 'bg-grad-6',
          },
          {
            text: 'Gradient 7',
            value: 'bg-grad-7',
          },
          {
            text: 'Gradient 8',
            value: 'bg-grad-8',
          },
        ],
        demoColor: 0,
        presets,
        skins
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      allowBlockAdd() {
        if (this.isLoading) return false;
        return !!(this.blocks.length < this.limits.blocks);
      },
      timerActive() {
        return this.blocks.some(function (block) {
          return block.activeSince !== 0;
        });
      },
    },
    watch: {
      saveProcess(val) {
        if (val === 'success') {
          this.saveProcess = false;
          this.$router.push({name: 'Tracker'});
          this.toast.success(`Your changes to the board were saved.`);
        }
      },
      timerActive() {
        this.calculateTimerTotal();
        this.calculateTimerSession();
      },
    },
    methods: {
      ...mapActions(["resetEverything", "projectsResetAll"]),
      blockIndexById(blockId) {
        let index = this.blocksData.findIndex(item => item.id === blockId);
        if (index > -1) {
          return index;
        }
      },
      resetBlocksAnon() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        blocksData.forEach((block) => {
          this.blockReset(block.id);
        });
        this.totalTime = 0;
        this.timerTotal = '00:00:00';
        this.toggleModal = false;
      },
      resetPinned(blocksData) {
        return new Promise((resolve) => {
          let promises = [];
          const toReset = blocksData;
          if (toReset.length) {
            toReset.forEach((block, index) => {
              toReset[index].activeSince = 0;
              toReset[index].elapsedTime = 0;
              toReset[index].elements = [];
              toReset[index].totalTime = 0;
              delete toReset[index].owner;
              promises.push(updateBlock(toReset[index].refID, toReset[index]));
            });
          }
          Promise.all(promises).then(()=>{
            resolve();
          });
        })
      },
      deleteUnpinned(blocksData) {
        return new Promise((resolve) => {
          let promises = [];
          // delete unpinned
          const toDelete = blocksData.filter(block => block.pinned !== true);
          if (toDelete.length) {
            toDelete.forEach((block) => {
              promises.push(deleteBlock(block.refID));
            });
            Promise.all(promises).then(()=>{
              resolve();
            });
          } else {
            resolve();
          }
        })
      },
      resetAll() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        this.resetPinned(blocksData)
            .then(() => {
              this.$store.commit('resetAll');
              this.totalTime = 0;
              this.timerTotal = '00:00:00';
              this.toggleModal = false;
              this.userContent();
            })
      },
      boardGridEditing() {
        if (this.boardGridEdit) {
          // TODO: warn about changes
          this.boardGridEdit = false;
          if (typeof this.boards[0].color !== "undefined") {
            if (this.boards[0].color.indexOf("bg-") >= 0) {
              this.updateBoardBG(this.boards[0].color);
              //this.$emit('updateBG', this.boards[0].color);
            } else {
              this.updateBoardBG('bg-' + this.boards[0].color + '-100');
              //this.$emit('updateBG', 'bg-' + this.boards[0].color + '-100');
            }
          }
          this.boardData.splice(0, this.boardData.length);
          this.blocksData.splice(0, this.blocksData.length);
          this.$router.push({name: 'Tracker'});
        } else {
          this.boardData = JSON.parse(JSON.stringify(this.boards));
          this.blocksData = JSON.parse(JSON.stringify(this.blocks));
          this.boardGridEdit = true;

          nextTick(() => {
            this.setTrackerHeight();
          });
        }
      },
      boardGridEditUpdate() {
        // Empty board
        if (this.boardData[0].rows.length < 1) {
          this.boardData[0].rows.push(
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              freshStart: false,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            });
        }

        this.$store.commit('boardAdd', this.boardData[0]);
        this.boardGridEdit = false;
        this.boardData.splice(0, this.boardData.length);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 500});
        }
      },
      boardAdd(board) {
        this.$store.commit('boardAdd', board);
      },
      boardAddRow() {
        const row = {
          id: uuidv4(),
          title: "",
          order: 0,
          freshStart: false,
          cols: [
            {
              title: "",
              id: uuidv4(),
              order: 1,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 2,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 3,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 4,
              data: [],
              color: null,
            }
          ]
        };
        this.boardData[0].rows.push(row);
      },
      boardRemoveRow(row) {
        let count = 0;
        this.boardData[0].rows[row].cols.forEach((col) => {
          count = count + col.data.length;
        });
        if (count === 0) {
          // this.$store.commit('boardRemoveRow', row);
          this.boardData[0].rows.splice(row, 1);
        } else {
          this.toast.warning(`Please remove any blocks from the columns within the row.`);
        }
      },
      boardAddColumn(row) {
        const column = {
          id: uuidv4(),
          title: "",
          order: 1,
          data: [],
          color: null
        };
        this.boardData[0].rows[row].cols.push(column);

        // this.$store.commit('boardAddColumn', {
        //   row: row,
        //   column: column
        // });
      },
      boardRemoveColumn(row, columnId) {

        let index = this.boardData[0].rows[row].cols.findIndex(item => item.id === columnId );
        this.boardData[0].rows[row].cols.splice(index, 1);

        // this.$store.commit('boardRemoveColumn', {
        //   row: row,
        //   columnId: columnId
        // });
      },
      colorGridSwatchEdit(id) {
        if (this.colorSwatchEdit === id) {
          this.colorSwatchEdit = null;
        } else {
          this.colorSwatchEdit = id;
        }
      },
      colorGridSwatch(element, color) {
        this.colorSwatchEdit = null;
        element.color = color;
      },
      colorBoardSwatch(type, color) {
        this.colorSwatchEdit = null;
        if (type === 'gradient') {
          this.updateBoardBG(color);
          //this.$emit('updateBG', color);
        } else {
          this.updateBoardBG('bg-' + color + '-100');
          //this.$emit('updateBG', 'bg-' + color + '-100');
        }
        this.boardData[0].color = color;
      },
      modeBoardSwatch(mode) {
        this.boardData[0].mode = mode;
        this.modeSwatchEdit = null;
      },
      blockReset(blockId) {
        this.$store.commit('blockReset', blockId);
      },
      blockAdd(type, id) {
        const color = this.getRandomColor();
        const randomId = uuidv4();
        const block = {
          id: randomId,
          title: 'Block Title',
          type: type,
          color: color,
          size: 2,
          increaseValue: 1,
          faceType: 'default',
          elapsedTime: 0,
          totalTime: 0,
          activeSince: 0,
          activeCountdownSince: 0,
          elements: [],
          isNew: true,
          refID: null
        };

        if (type === 'BlockCountdown') {
          block.size = 120;
        }

        if (type === 'BlockProgress') {
          block.progressStart = new Date().getTime();
          block.progressEnd = 0;
          block.counters = [];
        }

        if (type === 'BlockLink') {
          block.link = '';
          block.imageLink = '';
        }

        this.blocksData.push(block);

        this.animateButton('[data-im-id="button-' + id + '-add"]', block.id);

        this.blockAddBoard(block);
      },
      blockAddBoard(block) {
        const col_data_size = [];
        const columns = document.querySelectorAll("#row-0 .column");

        columns.forEach((col, colIndex) => {
          let lastBlockSize = col.getBoundingClientRect();
          let lastBlock = lastBlockSize.top;
          let lastBlocks = col.querySelectorAll(".column [data-draggable='true']");

          let someTop = 0;

          if (lastBlocks.length) {
            lastBlockSize = lastBlocks[lastBlocks.length - 1].getBoundingClientRect();
            lastBlock = lastBlockSize.top + lastBlockSize.height;
            someTop = 10;
          }

          let sizeCenter = 0;

          if (block.type === 'Block') {
            sizeCenter = lastBlock + 120 + someTop;
          } else {
            sizeCenter = lastBlock + 18 + someTop;
          }

          col_data_size.push({
            index: colIndex,
            size: lastBlock,
            sizeCenter: sizeCenter,
            x: lastBlockSize.x,
            y: lastBlockSize.y,
            width: lastBlockSize.width,
          });
        });
        col_data_size.sort((a, b) => a.size - b.size);

        const id = uuidv4();

        this.boardData[0].rows[0].cols[col_data_size[0].index].data.push({
          id: id,
          block: block.id
        });

        this.animeParticles(block, col_data_size[0]);
      },
      blockDelete(blockId) {
        this.blockDeleteBoard(blockId);

        let index = this.blocksData.findIndex(item => item.id === blockId );
        this.blocksData.splice(index, 1);

        this.calculateTimerTotal();
      },
      blockAdded(blockId) {
        let index = this.blocksData.findIndex(item => item.id === blockId );
        delete this.blocksData[index].isNew;
      },
      blockDeleteBoard(blockId) {
        // find the block in the board
        this.boardData[0].rows.forEach((row, rowIndex) => {
          this.boardData[0].rows[rowIndex].cols.forEach((col, index) => {
            let blockRef = col.data.findIndex(item => item.block === blockId);
            if (blockRef > -1) {
              this.boardData[0].rows[rowIndex].cols[index].data.splice(blockRef, 1);
            }
          })
        });
      },
      blockEditCancel(blockId, data) {
        if (data.title) this.blockEditTitle(blockId, data.title);
        if (data.size) this.blockEditSize(blockId, data.size);
        if (data.increaseValue) this.blockEditIncreaseValue(blockId, data.increaseValue);
        if (data.color) this.blockEditColor(blockId, data.color);
        if (data.link) this.blockEditLink(blockId, data.link);
        if (data.imageLink) this.blockEditImageLink(blockId, data.imageLink);
      },
      blockUpdate(blockRefID, blockId, blockData) {
        let index = this.blocksData.findIndex(item => item.id === blockId);

        if (blockData.title) this.blocksData[index].title = blockData.title;
        if (blockData.size) this.blocksData[index].size =  blockData.size;
        if (blockData.link) this.blocksData[index].link =  blockData.link;
        if (blockData.imageLink) this.blocksData[index].imageLink =  blockData.imageLink;
        if (blockData.increaseValue) this.blocksData[index].increaseValue =  blockData.increaseValue;
        if (blockData.color) this.blocksData[index].color = blockData.color;
        if (typeof blockData.projectRef !== 'undefined') this.blocksData[index].projectRef =  blockData.projectRef;

        if (typeof blockData.skin !== 'undefined') this.blocksData[index].skin = blockData.skin;
        if (typeof blockData.faceType !== 'undefined') this.blocksData[index].faceType = blockData.faceType;
        if (typeof blockData.elements !== 'undefined') this.blocksData[index].elements = blockData.elements;
        if (typeof blockData.collectors !== 'undefined') this.blocksData[index].collectors = blockData.collectors;
        if (typeof blockData.totalTime !== 'undefined') this.blocksData[index].totalTime = blockData.totalTime;

        if (typeof blockData.progressStart !== 'undefined') this.blocksData[index].progressStart = blockData.progressStart;
        if (typeof blockData.progressEnd !== 'undefined') this.blocksData[index].progressEnd =  blockData.progressEnd;
        if (typeof blockData.counters !== 'undefined') this.blocksData[index].counters = blockData.counters;

        if (typeof blockData.embedId !== 'undefined') this.blocksData[index].embedId = blockData.embedId;
        if (typeof blockData.embedOptions !== 'undefined') this.blocksData[index].embedOptions = blockData.embedOptions;

        // Update total time
        this.calculateTimerTotal();
      },
      blockNotificationUpdate(blockId, status) {
        this.$store.commit('blockNotificationUpdate', {
          id: blockId,
          status: status
        });
      },
      blockEditStart(blockId) {
        let index = this.blocksData.findIndex(item => item.id === blockId);
        if (typeof this.blocksData[index].type === 'undefined') this.blocksData[index].type = 'Block';
        this.blockEdited = this.blocksData[index];
      },
      blockEditStop() {
        this.blockEdited = {};
      },
      blockAddTime(blockId, time) {
        this.$store.commit('blockAddTime', {
          id: blockId,
          time: time
        });
      },
      blockRemoveTime(blockId, time) {
        this.$store.commit('blockRemoveTime', {
          id: blockId,
          time: time
        });
      },
      getRandomColor() {
        return this.colors[Math.floor(Math.random() * this.colors.length)].value;
      },
      calculateElementsTotal(elements) {
        let total = 0;
        elements.forEach((element) => {
          total = total + (element.end - element.start);
        });
        return total;
      },
      calculateTimerTotal() {
        this.timerTotal = '00:00:00';
      },
      calculateTimerSession() {
        this.timerSession = '00:00:00';
      },
      userContent() {
        if (this.currentUser) {
          this.isLoading = true;
          this.resetEverything();
          this.projectsResetAll();

          getProjects()
              .then(resp => {
                let projectsData = resp.data;
                let projects = [];

                projectsData.forEach(function (project) {
                  project.data.refID = project.ref.value.id;
                  projects.push(project.data);
                });
                this.$store.commit('projectsAdd', projects);
              })
              .catch(() => {
                this.toast.error(`We are not able to fetch projects from the database.`);
              });

          getBlocks()
              .then(resp => {
                let blocksData = resp.data;
                let blocks = [];
                blocksData.forEach(function (block) {
                  if (block.data.isNew) delete block.data.isNew;
                  block.data.refID = block.ref.value.id;
                  blocks.push(block.data);
                });
                this.$store.commit('blocksAdd', blocks);
                this.blocksData = blocks;
                this.getBoardsApp();
              })
              .catch(() =>
                this.toast.error(`We are not able to fetch blocks from the database.`)
              );
        } else {
          if (!this.boards.length) {
            this.boardAddDefault(false);
          }
        }
      },
      getBoardsApp() {
        getBoards()
            .then(resp => {
              let boardsData = resp.data;
              let board = null;
              boardsData.forEach(function (boardData) {
                board = boardData.data;
                board.refID = boardData.ref.value.id;
              });
              if (board === null) {
                // no board found
                // create default and save to db
                this.boardAddDefault(true);
              } else {
                // sanitize board
                board = this.boardSanitize(board);
                this.$store.commit('boardAdd', board);
                this.updateBoardBG(board.color);
                //this.$emit('updateBG', board.color);
              }
              this.isLoading = false;
              this.boardGridEditing();
            })
            .catch(() =>
                this.toast.error(`We are not able to fetch boards from the database.`)
            );
      },
      boardAddDefault(save = false) {
        let board = {
          title: "Default",
          id: uuidv4(),
          color: '',
          rows: [
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            }
          ]
        };

        if (this.blocks.length) {
          // If there are blocks but no board, it means it is an older version
          this.blocks.forEach((block, index) => {
            let pos = index;
            if (index > 3) pos = 1;
            board.rows[0].cols[pos].data.push(
                {
                  id: index,
                  block: block.id
                }
            );
          });
        }

        this.boardAdd(board);
        if (save) {
          createBoard(board)
              .then((res) => {
                this.$store.commit('boardUpdateRef', {refID: res.ref.value.id});
              })
              .catch(() => {
                this.toast.error(`We are not able to create the default board.`)
              });
        }
      },
      boardChange() {
        this.$store.commit('boardUpdate', this.boards[0]);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
        }
      },
      boardSanitize(board) {
        if (board.rows.length) {
          board.rows.forEach((row, rowIndex) => {
            row.cols.forEach((col, colIndex) => {
              col.data.forEach((block, blockIndex) => {
                if (typeof this.blockIndexById(block.block) === "undefined") {
                  board.rows[rowIndex].cols[colIndex].data.splice(blockIndex, 1);
                }
              });
            });
          });
        }
        return board;
      },
      updateBoardBG(className) {
        const elBody = document.querySelector("body");
        const classIndexRemove = [];
        elBody.classList.forEach((element) => {
          if (element.includes('bg-grad-')) {
            classIndexRemove.push(element);
          } else if (element.includes('-100')) {
            classIndexRemove.push(element);
          }
        });
        if (classIndexRemove.length) {
          classIndexRemove.forEach((element) => {
            elBody.classList.remove(element);
          });
        }

        if (className) {
          if (className.includes('bg-')) {
            elBody.classList.add(className);
          } else {
            elBody.classList.add('bg-' + className + '-100');
          }
        }
      },
      setTrackerHeight() {
        let trackerNavHeight = document.getElementById("app-nav").clientHeight;//tracker-nav
        let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let trackerHeight = viewportHeight - trackerNavHeight;
        //document.getElementById("tracker-main").style.height = trackerHeight + 'px';
        document.querySelector("body").style.height = trackerHeight + 'px';
      },
      demoChangeColor() {
        // find block
        // change color
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        this.demoColor = this.demoColor + 1;
        let skinIndex = Math.floor(Math.random() * 4);
        blockActive.skin = this.skins[skinIndex].value;
        blockActive.color = this.colors[this.demoColor].value;
      },
      animateButton(id) {
        nextTick(() => {
          anime({
            targets: id,
            easing: 'easeOutQuad',
            duration: 175,
            scale: [{value: 0.9},{value: 1}],
            //paddingBottom: [30],
            //marginTop: [20, 0],
            backgroundColor: ['#6EE7B7', '#bbf7d0'],
            borderBottom: [1, 3],
            boxShadow: ['inset 0px 0px 0px #34D399, inset 0 0px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5', 'inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5'],
            complete: () => {
              anime.remove(id);
            }
          });
          anime({
            targets: id + ' svg',
            easing: 'easeOutQuad',
            duration: 175,
            //scale: [{value: 0.95},{value: 1}],
            paddingBottom: [0, 0],
            paddingTop: [4, 0],
            complete: () => {
              anime.remove(id);
            }
          });
        });
      },
      bezier(t, p0, p1, p2, p3) {
        var cX = 3 * (p1.x - p0.x),
            bX = 3 * (p2.x - p1.x) - cX,
            aX = p3.x - p0.x - cX - bX;

        var cY = 3 * (p1.y - p0.y),
            bY = 3 * (p2.y - p1.y) - cY,
            aY = p3.y - p0.y - cY - bY;

        var x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
        var y = (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y;

        return {x: x, y: y};
      },
      animeParticles(block, endEl) {
        const startEl = 'button[data-im-id="button-' + block.type.toLowerCase() + '-add"]';
        const startElement = document.querySelector(startEl).getBoundingClientRect();
        const endX = ((endEl.width/2) - 30) + endEl.x;

        const animeContainers = document.querySelectorAll('.anime-container');

        setTimeout(() => {
          animeContainers.forEach((el) => {
            el.style.transform='translateX('+ (startElement.x + 1) +'px) translateY('+ (startElement.y - 6) +'px)';
            el.style.opacity = 0.7;
            el.style.display = 'block';
            el.style.width = 40 + 'px';
            el.style.height = 40 + 'px';
            el.style.borderWidth = 1 + 'px';
          });

          let accuracy = 0.01; //this'll give the bezier 100 segments
          let p0 = {x: startElement.x + 1, y: startElement.y - 6};
          let p1 = {x: startElement.x + 60, y: startElement.y - 270};
          let p2 = {x: endX - 200, y: endEl.sizeCenter + 150};
          let p3 = {x: endX, y: endEl.sizeCenter};

          const pointsX = [];
          const pointsY = [];

          for (var i=0; i<1; i+=accuracy) {
            var p = this.bezier(i, p0, p1, p2, p3);
            pointsX.push(p.x);
            pointsY.push(p.y);
          }

          let blockHeight  = 60;
          let blockWidth  = 60;

          if (block.type === 'Block') {
            blockHeight  = 70;
            blockWidth  = 70;
          }
          let colorIndex = this.colors.findIndex(item => item.value === block.color );

          anime({
            loop: false,
            easing: 'easeInSine',
            duration: 398,
            targets: '.anime-container',
            translateX: pointsX,
            translateY: pointsY,
            opacity: anime.stagger([0.2, 1]),
            //scale: anime.stagger([1, 2.3]),
            width: [44, blockWidth],
            height: [44, blockHeight],
            rotate: [180, 3],
            delay: anime.stagger(10, {direction: 'reverse'}),
            backgroundColor: ['#6EE7B7', '#' + this.colors[colorIndex].colors[200]],
            borderColor: ['#16A34A', '#' + this.colors[colorIndex].colors[700]],
            borderWidth: [1, 2, 2, 2.5, 2.7],
            complete: () => {
              animeContainers.forEach((el) => {
                el.style.opacity=0;
                el.style.display='none';
              });
            }
          });
        }, 200);
      },
      saveAll() {
        this.saveProcess = 'running';
        // ultimate function to save all blocks and board and/or update the blocks
        const concat = list => Array.prototype.concat.bind(list);
        const promiseConcat = f => x => f().then(concat(x));
        const promiseReduce = (acc, x) => acc.then(promiseConcat(x));
        const serial = funcs => funcs.reduce(promiseReduce, Promise.resolve([]));

        getBlocks()
          .then(resp => {
            const oldBlocks = [];
            const funcs = [];

            let oldBlocksData = resp.data;
            oldBlocksData.forEach(function (block) {
              delete block.data.owner;
              block.data.refID = block.ref.value.id;
              oldBlocks.push(block.data);
            });

            this.blocksData.forEach(function (block) {
              delete block.owner;
              block.elapsedTime = 0;
              // find in existing
              let index = oldBlocks.findIndex(item => item.id === block.id);
              // found
              if (index > -1) {
                // differences
                if (diffString(oldBlocks[index], block)) {
                  funcs.push(() => updateBlock(block.refID, block));
                }
                oldBlocks.splice(index, 1);
              } else {
                // not found
                funcs.push(() => createBlock(block));
                window.EngageKit.track('Block ' + block.type + ' Created');
              }
            });

            oldBlocks.forEach(function (block) {
              // delete blocks that are remaining
              funcs.push(() => deleteBlock(block.refID));
            });

            serial(funcs).then(() => {
              const oldBoardData = JSON.parse(JSON.stringify(this.boards));
              const newBoardData = JSON.parse(JSON.stringify(this.boardData));

              if (diffString(oldBoardData, newBoardData)) {
                delete this.boardData[0].owner;
                updateBoard(this.boardData[0].refID, this.boardData[0])
                  .then(() => {
                    this.saveProcess = 'success';
                    window.EngageKit.track('Board Saved');
                  })
                  .catch(() => {
                    this.saveProcess = 'failed';
                    this.saveProcessError = 'Something went wrong. We were not able to save what you have built.';
                  });
              } else {
                this.saveProcess = 'success';
                window.EngageKit.track('Board Saved');
              }
            })
            .catch(() => {
              // Blocks update/delete/create failed
              this.saveProcess = 'failed';
              this.saveProcessError = 'Something went wrong. We were not able to save what you have built.';
            });
          })
          .catch(() => {
            this.saveProcess = 'failed';
            this.saveProcessError = 'Something went wrong. We were not able to save what you have built.';
          });
      },
      saveBackup() {
        const oldBoardData = JSON.stringify(this.boards);
        const newBoardData = JSON.stringify(this.boardData);
        const oldBlocksData = JSON.stringify(this.blocks);
        const newBlocksData = JSON.stringify(this.blocksData);

        const blob = new Blob([oldBoardData, newBoardData, oldBlocksData, newBlocksData], { type: "text/json" });
        const link = document.createElement("a");

        link.download = 'kairoBuildBackUp.json';
        link.href = window.URL.createObjectURL(blob);
        link.dataset.downloadurl = ["text/json", link.download, link.href].join(":");

        const evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        link.dispatchEvent(evt);
        link.remove();
      }
    },
    mounted () {
      const blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
      const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
      if (blockActive || blockCountdownActive.length > 0) {
        this.$router.push({name: 'Tracker'});
        this.toast.warning(`Please stop any running blocks to be able to switch to Build mode.`);
      } else {
        this.userContent();

        // set background
        if (this.boards.length) {
          if (typeof this.boards[0].color !== "undefined") {
            if (this.boards[0].color.indexOf("bg-") >= 0) {
              this.updateBoardBG(this.boards[0].color);
              //this.$emit('updateBG', this.boards[0].color);
            } else {
              this.updateBoardBG('bg-' + this.boards[0].color + '-100');
              //this.$emit('updateBG', 'bg-' + this.boards[0].color + '-100');
            }
          }
        }

        this.calculateTimerTotal();
        this.calculateTimerSession();

        // set height
        window.addEventListener("resize", this.setTrackerHeight);

        nextTick(() => {
          this.setTrackerHeight();
        });
      }
    },
    unmounted() {
      this.updateBoardBG(false);
      //this.$emit('updateBG', 'none');
      window.removeEventListener("resize", this.setTrackerHeight);
    },
    beforeUnmount () {
    },
  }
</script>
<style>
  .custom-outline {
    box-shadow: 0 0 1px 1px rgba(100,100,100,0.2);
    background-color: rgba(100,100,100,0.1);
  }
  .col-transition {
    transition-property: background-color, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  .block-drag-class .block-component {
    height: 90px !important;
    overflow: hidden;
  }
  .block-drag-class .block-component canvas,
  .block-drag-class .block-component [id^='block-face-'] svg {
    display: none;
  }
  .bounce-enter-active {
    animation: bounceme .6s linear both;
  }
  .bounce-leave-active {
    animation: bounce-in .2s reverse;
  }
  .bounce-add {
    animation: bounceme .7s linear .4s both;
  }

  @keyframes bounceopacity {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .bg-grad-1 {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  .bg-grad-2 {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  .bg-grad-3 {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  }
  .bg-grad-4 {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .bg-grad-5 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  }
  .bg-grad-6 {
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  }
  .bg-grad-7 {
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  }
  .bg-grad-8 {
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  }


  /* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.2%2Cy%3A0.2%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.5%2Cy%3A0.5%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */
  @keyframes bounceme {
    0% { -webkit-transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0;}
    3.4% { -webkit-transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0.9; }
    4.7% { -webkit-transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.81% { -webkit-transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    9.41% { -webkit-transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.21% { -webkit-transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    13.61% { -webkit-transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    14.11% { -webkit-transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    17.52% { -webkit-transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.72% { -webkit-transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    21.32% { -webkit-transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    24.32% { -webkit-transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.23% { -webkit-transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.03% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.93% { -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.54% { -webkit-transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.74% { -webkit-transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.04% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { -webkit-transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.15% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    59.86% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    63.26% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.28% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.49% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.69% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 1; }
  }

  .anime-container {
    top: 0;
    left: 0;
    z-index: 100;
  }
  .collector-anim {
    top: 0;
    left: 0;
    z-index: 39;
  }

</style>
